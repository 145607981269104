import { RenderStringType } from "./types";

import "./scss/index.scss";

export const renderString = ({ value, properties }: RenderStringType) => {
  if (!value) return null;
  const { className: newClass } = properties ?? {};

  const className: string = `table-cell-string ${newClass ?? ""}`;

  return <div className={className}>{value}</div>;
};
