import { NoValue } from "./components/NoValue";
import { RenderArray } from "./components/RenderArray";
import { renderBadge } from "./components/RenderBadge";
import { renderBoolean } from "./components/RenderBoolean";
import { renderDate } from "./components/RenderDate";
import { renderNumber } from "./components/RenderNumber";
import { renderString } from "./components/RenderString";
import { renderURL } from "./components/RenderURL";
import { GetValueProps } from "./types";

export const getValue = ({
  record,
  apiParam,
  properties,
  type,
  ...rest
}: GetValueProps): JSX.Element | null => {
  const [headerKey, subKey] = Array.isArray(apiParam)
    ? apiParam
    : [apiParam, undefined];

  // Retrieve the record value based on apiParam and subKey
  const value = subKey ? record[headerKey]?.[subKey] : record[headerKey];

  if (value === null || value === undefined) return <NoValue />;

  if (Array.isArray(value)) <RenderArray {...{ value, properties }} />;

  // Map component types to their corresponding component functions
  const componentMap: Record<string, any> = {
    string: renderString,
    boolean: renderBoolean,
    number: renderNumber,
    url: renderURL,
    badge: renderBadge,
    date: renderDate,
  };

  const component = componentMap[type || typeof value];

  if (!component) return <NoValue />;

  return component({ value, headerKey, properties, ...rest });
};
