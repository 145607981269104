import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-standing-orders-list");
const filtersURL: string = getURL(
  "api:internal-standing-orders-schema-options"
);

export const StandingOrders: FC = () => {
  const filterOptions = useFilters(filtersURL);
  const handleAddRecord = () =>
    (window.location.href = "/internal/standing-order-crud-form/");

  return (
    <Table
      id={"id"}
      sortBy="-next_order"
      addRecord={handleAddRecord}
      navbar={{ header: "All Standing Orders", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "REPEATS", apiParam: "frequency" },
        { title: "DAY", apiParam: "day" },
        { title: "NEXT ORDER", apiParam: "next_order", type: "date" },
        {
          title: "STATUS",
          apiParam: "state",
          type: "badge",
          properties: {
            badgeColor: {
              "running": "success",
              "paused": "neutral",
              "canceled": "destructive",
              "expired": "destructive",
            },
          },
        },
      ]}
      filters={{ ...filterOptions, allowFutureDates: ["next_order"] }}
      actions={["standing_order_activity", "edit_standing_order"]}
      url={url}
    />
  );
};
