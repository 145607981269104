import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { GrayFlag, RedFlag } from "../Orders/assets";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-orders-ordered");
const filtersURL: string = getURL("api:internal-orders-schema-options");

export const OrderedOrders: FC = () => {
  const filterOptions = useFilters(filtersURL, [
    "in_transit_at",
    "received_at",
    "flagged_at",
    "canceled_at",
    "order_status",
  ]);

  return (
    <Table
      id={"facility_id"}
      navbar={{ header: "Ordered Orders", input: true }}
      sortBy="-ordered_at"
      headers={[
        { title: "#", apiParam: "id" },
        { title: "PLACED ON", apiParam: "ordered_at", type: "date" },
        { title: "TYPE", apiParam: "order_type" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "AUDIENCE", apiParam: "audience" },
        {
          title: " ",
          apiParam: "is_flagged",
          type: "boolean",

          properties: {
            booleanType: {
              true: <RedFlag />,
              false: <GrayFlag />,
            },
          },
        },
      ]}
      filters={filterOptions}
      url={url}
      actions={["order_activity", "edit_order"]}
    />
  );
};
