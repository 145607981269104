import dispositionForm from "@root/bloodbuy/assets/files/bloodbuy_disposition_form.pdf";
import { Button } from "@/components/Button";
import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { Todo } from "@/types";

import "./scss/index.scss";

export const OrderActionModal = ({
  recordID: orderID,
  hasDispositionForm,
  onCloseModal,
  header = "order header",
  saveButtonLabel = "Save",
}: ModalTemplateProps) => {
  //Todo: Replace with actual data
  //Todo: https://bloodbuy.atlassian.net/jira/software/c/projects/BB/boards/27?selectedIssue=BB-4895&useStoredSettings=true
  const todoOptions: Todo = [];

  return (
    <div className="orders-actions-modal-container">
      <h4 className="orders-actions-modal-header">
        {header} #{orderID}
      </h4>
      <DropdownSingleSelect options={todoOptions} header="Reason" />
      {hasDispositionForm ? (
        <p className="orders-actions-modal-text">
          Supplier may require you to submit a disposition form to process
          credits for non transfusable units.{" "}
          <a href={dispositionForm} target="_blank" rel="noopener noreferrer">
            Download a disposition form
          </a>
        </p>
      ) : null}
      <div className="orders-actions-modal-notes-container">
        <label className="orders-actions-modal-notes-label">
          Notes (optional)
        </label>
        <textarea
          className="orders-actions-modal-notes-textarea"
          name="order--actionnotes"
          id="order--actionnotes"
          rows={5}
          placeholder="Please note any details about this order so we can follow-up."
        />
      </div>
      <div className="buttons-container">
        <Button
          onClick={onCloseModal}
          size={"medium"}
          color={"primary"}
          state={"link"}
          label={"Cancel"}
        />
        <Button
          size={"medium"}
          color={"primary"}
          state={"primary"}
          label={saveButtonLabel}
        />
      </div>
    </div>
  );
};
